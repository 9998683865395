<template>
    <v-content>
        <div v-if="! moduleReport">
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>
                <v-col cols="2"
                       v-for="title in titles"
                       v-bind:key="title.link"
                       class="viewCard">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">
                                <HoverCard :cardData="title"></HoverCard>
                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>
        <!-- <component :is="componentName"
                    :moduleName="moduleName"
                    @goBackToReportDashboard="goBackToReportDashboard"
                    @goBackToReportSelection="goBackToReportSelection"
                    @goToReport="goToReport"
                    v-else
         ></component>-->
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "Dashboard",
        watch: {},
        components: {
            HoverCard
            /*  selectReportTypeComponent: () => import(/!* webpackChunkName: "selectReportTypeComponent" *!/ '../Modules/Module'),
              monthlyYearlyReport: () => import(/!* webpackChunkName: "monthlyYearlyReport" *!/ '../History/HistoryList'),
              reportHeaderSetting: () => import(/!* webpackChunkName: "reportHeaderSetting" *!/ '../ReportColumn/TrekkingList'),
              SaarcReport: () => import(/!* webpackChunkName: "SaarcReport" *!/ '../HistorySaarc/HistoryList'),*/
        },
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('modules_name.customer_visit_homestay'),
                        link: "/IndividualHomestayReport/CustomerVisitHomestay",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('modules_name.customer_visit'),
                        link: "/IndividualHomestayReport/customerVisit",
                        icon: "mdi-chart-tree"
                    },
                    {
                        name: this.$t('modules_name.occupied_homestay_house'),
                        link: "/IndividualHomestayReport/occupiedHomestayHouse",
                        icon: "mdi-chart-multiple"
                    },
                    //{
                    //    name: this.$t('modules_name.customer_visit_countrywise'),
                    //    link: "/IndividualHomestayReport/customerVisitCountryWise"
                    //},
                   
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }
                    ]
            }
        },
        data() {
            return {

                /*    modules: [
                        {
                            name: "Trekking",
                        }
                    ],*/
                //items: [
                //    {
                //        text: "Dashboard",
                //        disabled: true,
                //        to: "/dashboard",
                //        exact: true
                //    }],
                moduleReport: false,
                moduleName: '',
                componentName: ''
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },

            goBackToReportSelection() {
                this.componentName = 'selectReportTypeComponent'
            },

            goBackToReportDashboard() {
                this.moduleReport = false
            },

            goToReport(type) {
                this.componentName = type
                console.log('this,moduleName: ', this.moduleName)
            },

            goToModuleReport(moduleName) {
                this.moduleName = moduleName
                this.componentName = 'selectReportTypeComponent'
                this.moduleReport = true
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>